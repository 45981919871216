import { Button } from '@pidk/common/src'
import Modal, { ModalActions } from '@pidk/common/src/components/Modal/Modal'
import styled from 'styled-components'
import type { RoomParticipant } from 'vuurrood-sockets'

import useSocket from '../../hooks/useSocket'

const RoomCode = styled.strong`
  display: block;
  font-weight: bold;
  font-size: 2rem;
`

interface IRoomModal {
  onDeactivateRoom: () => Promise<any>
  onClose: () => void
}

const RoomModal = ({ onClose, onDeactivateRoom }: IRoomModal) => {
  const socketContext = useSocket()
  const voteUrl = `${window.location.hostname}/stem`
  const amountParticipants = socketContext.state.room?.participants.reduce((output: number, p: RoomParticipant) => {
    if (p.isConnected) {
      return output + 1
    }

    return output
  }, 0)

  return (
    <Modal
      type='dialog'
      onClose={onClose}
    >
      <p>Ga naar <strong>{voteUrl}</strong><br />Voer de onderstaande code in:</p>
      <RoomCode>{socketContext.state.room?.code}</RoomCode>

      <ModalActions>
        <Button
          variant={Button.Variant.SECONDARY}
          onClick={onClose}
        >
          {amountParticipants} {amountParticipants === 1 ? 'Deelnemer' : 'Deelnemers'}
        </Button>
        <Button
          variant={Button.Variant.SECONDARY}
          onClick={onDeactivateRoom}
        >
          Doorgaan zonder eigen devices
        </Button>
      </ModalActions>
    </Modal>
  )
}

export default RoomModal
